<template>
  <div
    class="upload-box"
    :class="{ fullmode: fullmode }"
  >
    <input
      :multiple="multiple"
      type="file"
      accept="image/*"
      @change="$emit('change', $event)"
    />

    <button
      v-if="!fullmode"
      class="btn btn-primary"
    >
      <i class="fa fa-image"></i> <slot />
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      fullmode: { type: Boolean, default: false },
      multiple: { type: Boolean, defualt: false }
    }
  }
</script>

<style lang="scss" scoped>
  .upload-box {
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    cursor: pointer;
    }
  }

  .fullmode {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: .5s all;

    &:hover {
      background: rgba(#000, .1);
    }
  }
</style>