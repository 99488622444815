<template>
  <div class="page-with-title">
    <page-title>
      <h1>Gerenciar Vitrine</h1>
    </page-title>
    <div class="p-4">
      <div class="row justify-content-center">
        <div class="col-9">
          <h4 class="mb-2">Desktop</h4>
          <img
            :src="highlightBanners[0].imagem_url"
            class="img-fluid"
          />
          <div class="d-flex align-items-center mt-3">
            <UploadButton
               @change="$e => uploadImage($e, highlightBanners[0])"
             />
            <div class="w-100 mr-4 ml-4">
              <input
                v-model="highlightBanners[0].titulo"
                class="form-control"
                type="text"
                placeholder="Título"
              />
              <input
                v-model="highlightBanners[0].url"
                class="form-control mt-2"
                type="text"
                placeholder="URL"
              />
            </div>
            <button
              class="btn btn-2 edit-btn pr-5 pl-5"
              @click="saveEditing(highlightBanners[0])"
            >Salvar</button>
          </div>
        </div>
        <div class="col-12">
          <hr class="mt-4 mb-4" />
        </div>
        <div class="col-9">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <h4 class="mb-2">Mobile</h4>
              <img
                :src="highlightBanners[1].imagem_url"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mt-3">
            <UploadButton
               @change="$e => uploadImage($e, highlightBanners[1])"
             />
             <div class="w-100 mr-4 ml-4">
               <input
                 v-model="highlightBanners[1].titulo"
                 class="form-control"
                 type="text"
                 placeholder="Título"
               />
               <input
                 v-model="highlightBanners[1].url"
                 class="form-control mt-2"
                 type="text"
                 placeholder="URL"
               />
             </div>
             <button
               class="btn btn-2 edit-btn ml-3 pr-5 pl-5"
               @click="saveEditing(highlightBanners[1])"
             >Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import UploadButton from '@/components/utils/UploadButton'
  import { getHighlightBanners, updateHighlightBanner } from '@/api/songs'

  export default {
    components: { PageTitle, UploadButton },
    data: () => ({
      isEditing: false,
      highlightBanners: []
    }),
    mounted() {
      this.fetchHighlightBanners()
    },
    methods: {
      async fetchHighlightBanners() {
        this.highlightBanners = await getHighlightBanners()
      },
      setEditing(id) {
        this.isEditing = id
      },
      saveEditing(banner) {
        this.isEditing = false
        updateHighlightBanner(banner)
      },
      uploadImage(e, banner) {
        const file = e.target.files[0]
        banner.imagem = file
        const reader = new FileReader()

        reader.onloadend = function() {
          banner.imagem_url = reader.result
        }

        if (file) {
          reader.readAsDataURL(file)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/styles/variables";
  
  .motd-box {
  }
</style>